import * as React from 'react'

import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout'
import Seo from '../components/Seo';

const ImprintPage = ({ data }) => {

  const { t } = useTranslation();

  return (
    <Layout >
      <Seo
        title={t("Imprint")}
      />
      <div className="max-w-md mx-auto bg-grey-light dark:bg-grey-dark rounded-xl shadow-md overflow-hidden md:max-w-2xl">
        <div
          className="h-full"
        >
          <div
            className="p-6 mb-2 rounded-xl bg-grey-light dark:bg-grey-dark shadow-md text-text-light dark:text-text-dark"
          >
            <div dangerouslySetInnerHTML={{ __html: data.imprint.childMarkdownRemark.html }} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ImprintPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    imprint: file(name: {eq: "Imprint"}) {
      childMarkdownRemark {
        html
      }
    }
  }
`;